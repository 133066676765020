import React from 'react';
import LogoImage from 'assets/images/logo.png';
import styled from 'styled-components';

const Image = styled.img<{ height: number }>`
    height: ${ ( { height } ) => `${ height }px` };
`;

interface LogoProps {
    height?: number
}

const Logo: React.FC<LogoProps> = ( props ) => {
    const { height } = props;

    return (
        <Image
            src={ LogoImage }
            alt="Dragoge"
            height={ height }
        />
    );
};

Logo.defaultProps = {
    height: 400
};

export default Logo;