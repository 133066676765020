import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import GlobalStyle from 'style/Global';
import Home from './views/Home';
import ResetCSS from './style/ResetCSS';

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <ResetCSS/>
            <GlobalStyle/>
            <Switch>
                <Route path='/'>
                    <Home/>
                </Route>
            </Switch>
        </BrowserRouter>
    );
};

export default React.memo( App );
