import React from 'react';
import Section from './components/Section';
import { BinocularsIcon, Box, Flex, MediumIcon, SwapIcon, TelegramIcon, Text, TwitterIcon } from 'components';
import { Backgrounds } from './types';
import Link from './components/Link';
import styled from 'styled-components';
import Logo from './components/Logo';

import DragogeVideoMp4 from 'assets/videos/dragoge.mp4';
import DragogeVideoWebm from 'assets/videos/dragoge.webm';
import DragogeVideoOgg from 'assets/videos/dragoge.ogg';

const HomeWrapper = styled( Flex )`
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const Video = styled.video`
    height: 100%;
`;

const TOKEN_ADDRESS = '0x5084189B91DCE8F01eF851f4B2e7781a3c39e1b2';
const BIG_TEXT_FONT_SIZE = '1.6rem';

const Home: React.FC = () => {
    return (
        <HomeWrapper>
            { /* Heading */ }
            <Section background={ Backgrounds.BLACK }>
                <Logo height={ 500 }/>

                <Text mt={ 3 }>
                    contract address: { TOKEN_ADDRESS }
                </Text>

            </Section>

            { /* Intro */ }
            <Section background={ Backgrounds.DRAGON }>
                <Video
                    loop
                    preload="auto"
                    autoPlay
                    muted
                    playsInline
                >
                    <source src={ DragogeVideoMp4 } type="video/mp4"/>
                    <source src={ DragogeVideoWebm } type="video/webm"/>
                    <source src={ DragogeVideoOgg } type="video/ogg"/>
                    <span>Your browser does not support the video tag.</span>
                </Video>
            </Section>

            { /* Details */ }
            <Section background={ Backgrounds.BLACK }>
                <Box width={ 820 } mx='auto'>
                    <Text
                        textAlign='left'
                        my={ 4 }
                        fontSize={ BIG_TEXT_FONT_SIZE }
                    >
                        GOGE IS THE GOD OF DOGE, THE DAO, AND THE PATH TO THE GREAT LIBERATION THROUGH THE YOGA OF KNOWING THE ONE MEME
                    </Text>
                </Box>
            </Section>

            { /* Links */ }
            <Section
                minHeight={ 500 }
                background={ Backgrounds.DRAGON }
            >
                <Flex
                    alignItems='center'
                    justifyContent='center'
                    flexWrap='wrap'
                    mx='auto'
                    width={ 720 }
                    height='100%'
                >
                    <Link icon={ TwitterIcon } url='https://twitter.com/dragogedog'/>
                    <Link icon={ TelegramIcon } url='https://t.me/dragogedog'/>
                    <Link icon={ MediumIcon } url='https://medium.com/dragogedog'/>
                    <Link icon={ BinocularsIcon } url='https://explorer.dogechain.dog/'/>
                    <Link icon={ SwapIcon } url={ `https://dogeswap.org/#/swap?outputCurrency=${ TOKEN_ADDRESS }` }/>
                </Flex>
            </Section>

            { /* Footer */ }
            <Section background={ Backgrounds.BLACK }>
                <Logo height={ 150 }/><br/>
                <Text small mt={ 3 }>
                    Made with 🐕❤️ by ESI
                </Text>
            </Section>
        </HomeWrapper>
    );
};

export default Home;