import React from 'react';
import styled from 'styled-components';
import { Backgrounds } from '../types';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';
import SectionBackground from 'assets/images/section-background.png';

interface SectionProps extends LayoutProps, SpaceProps {
    background: Backgrounds
}

const SectionWrapper = styled.div<{ background: Backgrounds }>`
    flex: 1;
    width: 100%;
    text-align: center;
    
    ${ ( { background } ) => background === Backgrounds.BLACK ? `
        background-color: #000000;
        color: white;
    ` : `
        background-color: rgb(200,170,101);
        background-image: url(${ SectionBackground }), linear-gradient(90deg, #CFA64C 0%, #F4DF9B 100%);
        background-repeat: no-repeat;
        background-position: right center;
        color: #000000;
    ` };
    
    ${ space }
    ${ layout }
`;

const Section: React.FC<SectionProps> = ( props ) => {
    const { background, children, ...otherProps } = props;

    return (
        <SectionWrapper
            background={ background }
            { ...otherProps }
        >
            { children }
        </SectionWrapper>
    );
};

Section.defaultProps = {
    p: 5
};

export default Section;