import React, { FC } from 'react';
import styled from 'styled-components';
import { SvgProps } from 'components';

const Anchor = styled.a`
    display: block;
    margin: 0;
    margin-right: 6rem;
    
    svg {
        transition: transform, fill, 0.3s;
    }
    
    &:hover {
        svg {
            transform: scale(1.1);
            fill: rgba(0, 0, 0, 0.75);
        }
    }
`;

interface LinkProps {
    url: string
    icon: FC<SvgProps>;
}

const Link: React.FC<LinkProps> = ( props ) => {
    const { url, icon: Icon } = props;

    return (
        <Anchor href={ url } target='_blank' rel='noreferrer'>
            <Icon width={ 100 }/>
        </Anchor>
    );
};

export default Link;