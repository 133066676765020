import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<SvgProps> = ( props ) => {
    return (
        <Svg viewBox="0 0 488 488" { ...props }>
            <path d="M299.9,86.8c14.2-6.3,30.3-9.8,46.2-9.8c9.2,0,18,1.1,26.3,3.2c3.3,0.8,6.5-2,6-5.4l-1.2-9.6l0,0
			c-1-16-19.3-28.9-41.2-28.9c-22.5,0-44,13.5-44,30.2l1,16C293.3,86,296.8,88.2,299.9,86.8z"/>
            <path d="M487.3,361.9l-0.5-2.9c0-0.2-0.1-0.4-0.1-0.6l-18.5-94c-2.4-21.7-18.2-40.6-41.2-52.3l-8.8-68.3c0-29-32.8-52.7-72.1-52.7
			s-76.7,23.5-76.7,52.6c0,1.4,0.1,2.9,0.3,4.3l0.5,7.4c-7.8-2.8-16.7-4.4-26.1-4.4c-9.5,0-18.4,1.6-26.3,4.5l0.5-7.5
			c0.2-1.4,0.3-2.8,0.3-4.3c0-29-37.5-52.6-76.7-52.6c-39.3,0-72.1,23.7-72.1,52.7L61,212.1c-23,11.6-38.8,30.6-41.2,52.3l-18.5,94
			c0,0.2-0.1,0.4-0.1,0.6l-0.5,2.9l0,0c-0.4,3-0.7,6-0.7,9.1c0,44.6,46.7,80.8,104.2,80.8s104.2-36.2,104.2-80.8l0,0l0,0v-37
			c10.3,4.8,22.6,7.5,35.7,7.5s25.3-2.7,35.6-7.4V371l0,0l0,0c0,44.6,46.7,80.8,104.2,80.8c57.4,0,104.2-36.2,104.2-80.8
			C488,367.9,487.7,364.9,487.3,361.9z M104.2,422.7c-40.7,0-75.1-23.7-75.1-51.7c0-28.1,34.4-51.7,75.1-51.7s75.1,23.7,75.1,51.7
			C179.3,399,144.9,422.7,104.2,422.7z M244.1,315.9c-17.1,0-31-9.7-31-21.6s13.9-21.6,31-21.6s31,9.7,31,21.6
			C275.1,306.2,261.2,315.9,244.1,315.9z M383.8,422.7c-40.7,0-75.1-23.7-75.1-51.7c0-28.1,34.4-51.7,75.1-51.7s75.1,23.7,75.1,51.7
			S424.5,422.7,383.8,422.7z"/>
            <path d="M115.5,80.2c8.3-2.1,17.2-3.2,26.3-3.2c15.9,0,32.1,3.5,46.2,9.8c3.1,1.4,6.6-0.8,6.8-4.2l1-16c0-16.7-21.5-30.2-44-30.2
			c-21.9,0-40.1,12.9-41.2,28.9l0,0l-1.2,9.6C109,78.2,112.1,81.1,115.5,80.2z"/>
        </Svg>
    );
};

export default Icon;
